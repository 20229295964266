<template>
	<div class="files-main-list">
		<div class="file-toolbar__wrapper">
			<div class="btn-group__wrapper" style="float: left">
				<!--        <div style="display: none">-->
				<!--          <el-upload action="" ref="uploader"></el-upload>-->
				<!--        </div>-->
				<!-- 顶部操作按钮 -->
				<el-button-group>
					<!-- 上传控件 -->
					<input id="upload-input" type="file" ref="uploadInput" name="file" style="display: none;"
						@change="uploadFile" multiple />

					<input id="upload-input2" type="file" ref="uploadInput" name="file" style="display: none;"
						@change="uploadFile" webkitdirectory directory multiple />

					<el-button v-if="selectStatus == 0 && pasteStatus == 0" size="small" icon="el-icon-upload2"
						@click="toUpload">上传文件
					</el-button>
					<el-button size="small" icon="el-icon-upload" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="toUploadDirectory">
						上传文件夹</el-button>
					<el-button size="small" icon="el-icon-folder-add" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="createFolderConfirm">
						新建文件夹
					</el-button>
					<el-button size="small" icon="el-icon-folder-add" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="createFolderConfirm">
						新建文本
					</el-button>
					<!-- 	<el-button size="small" icon="el-icon-upload" v-if="selectStatus == 0 && pasteStatus == 0"
						@click="offlineDownload">
						离线下载</el-button> -->
					<el-button size="small" icon="el-icon-folder-add"
						v-if="selectStatus == 1 && previewStatus == 1 && pasteStatus == 0" @click="previewImg">预览
					</el-button>
					<!-- <el-button size="small" icon="el-icon-cencle" v-if="selectStatus == 1" @click="allSelection">
						全选
					</el-button> -->
					<el-button size="small" icon="el-icon-video-camera"
						v-if="selectStatus == 1 && pasteStatus == 0 && multipleSelection.length==1 && multipleSelection[0].isDir==0 && multipleSelection[0].category==1 && !multipleSelection[0].description"
						@click="addToMovie">添加到影视库
					</el-button>
					<el-button size="small" icon="el-icon-video-camera"
						v-if="selectStatus == 1 && pasteStatus == 0 && multipleSelection.length==1 && multipleSelection[0].isDir==0 && multipleSelection[0].category==1 && multipleSelection[0].description"
						@click="removeOfMovie">移出影视库
					</el-button>
					<el-button size="small" icon="el-icon-share" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="centerDialogVisible = true">分享
					</el-button>
					<el-button size="small" icon="el-icon-download" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="downloadFile">下载
					</el-button>

					<!-- <el-button size="small" icon="el-icon-s-check" v-if="selectStatus == 1" @click="sharedFile">共享
					</el-button> -->
					<!-- <el-button size="small" icon="el-icon-s-custom" v-if="selectStatus == 1"
						@click="cancelShared">取消共享</el-button> -->
					<el-button size="small" icon="el-icon-edit"
						v-if="selectStatus == 1 && renameStatus == 1 && pasteStatus == 0" @click="rename">
						重命名
					</el-button>

					<!-- <el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1" @click="copyFiles">复制					</el-button> -->
					<el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="moveFiles">移动 </el-button>
					<el-button size="small" icon="el-icon-delete" v-if="pasteStatus == 1" @click="cencleMove">取消移动
					</el-button>
					<el-button size="small" icon="el-icon-delete" v-if="pasteStatus == 1" @click="pasteFiles">粘贴
					</el-button>

					<el-button size="small" icon="el-icon-delete" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="deleteFiles">删除
					</el-button>
					<el-button size="small" icon="el-icon-cencle" v-if="selectStatus == 1 && pasteStatus == 0"
						@click="cancelSelection">
						取消选中
					</el-button>
				</el-button-group>
			</div>
			<!-- 搜索框 -->
			<div class="btn-search__wrapper" style="float:right">
				<input placeholder="搜索我的文件" v-model="keyword" class="pc-search__wrapper" @keydown.enter="getFile">
				</input>
				<el-button class="pc-search__wrapper_button" slot="append" icon="el-icon-search"
					@click="getFile()"></el-button>
			</div>
		</div>

		<div class="files-list-toolbar__wrapper">
			<div class="files-list-left__wrapper">
				<div>
					<span class="tittle" v-if="currentPathName != '/'" @click="lastPage"
						style="cursor:pointer; color: #3a8ee6">返回上一级</span>
					<span class="tittle" v-if="currentPathName != '/'"> ｜ </span>
					<span class="tittle" v-if="currentPathName != '/'" @click="firstPage"
						style="cursor:pointer; color: #3a8ee6">全部文件 </span>
					<span class="tittle">当前路径：{{ currentPathName }}</span>
				</div>
			</div>
			<div class="files-list-right__wrapper">
				<span class="files-right-count">已加载全部，共{{ fileDataList.length }}个</span>
				<i @click="changesIconStatus" class="el-icon-menu" style="height: 30px; width: 30px; font-size: 1rem"
					v-if="iconStatus == 0"></i>
				<i @click="changesIconStatus" class="el-icon-s-unfold"
					style="height: 30px; width: 30px; font-size: 1rem;" v-if="iconStatus == 1"></i>
				<span @click="changesIconStatus">切换视图</span>
			</div>
		</div>

		<!-- 文件列表 -->
		<div class="no-main-file-list nd-main-list__table">
			<div class="nd-table is-show-header">
				<template>
					<div class="box">
						<div class="wrap">
							<div class="imgContent" @click="showImgViewer"></div>

							<!-- 通用预览弹框 -->
							<el-dialog :title="preview.title" :visible.sync="preview.visible" width="50%"
								@close="closePreview">

								<!-- 图片预览 -->
								<template v-if="preview.type === 'image'">
									<el-image :src="preview.content" alt="预览图片"
										style="width: auto; height: auto; max-width: 100%; max-height: calc(100vh - 40vh); display: block; margin: 0 auto; object-fit: contain;">
									</el-image>

								</template>
								<template v-if="preview.type === 'video'">
									<video :src="preview.content" autoplay controls
										style="width: auto; height: auto; max-width: 100%; max-height: calc(100vh - 40vh); display: block; margin: 0 auto; object-fit: contain;">
									</video>
								</template>

								<template v-if="preview.type === 'txt'">
									<textarea v-model="preview.content" class="txt-editor" style="width: 100%; height: calc(100vh - 55vh);border: 1px solid rgb(0, 0, 0, 0.4);
									padding: 10px;">
									</textarea>

								</template>

								<template #footer>
									<el-button v-if="preview.type === 'txt'" @click="saveTxtFile">保存</el-button>
									<el-button v-if="preview.type === 'txt'" @click="closePreview">关闭</el-button>
								</template>
							</el-dialog>

						</div>


						<div id="drop-area" :class="dropActive ? 'drop-active' : ''">
							<div v-if="fileDataList.length==0" style="margin-top: 30%;">
								<svg t="1729848306706" class="icon" viewBox="0 0 1255 1024" version="1.1"
									xmlns="http://www.w3.org/2000/svg" p-id="4774" width="200" height="200">
									<path
										d="M214.711547 663.146264L0 743.501283l997.221434 271.456604 250.686792-154.759245-929.521509-219.812227z"
										fill="#D8E0EA" opacity=".3" p-id="4775"></path>
									<path
										d="M329.380226 0L568.223396 38.525585l60.783095 105.491321 596.837434 122.454943-132.926793 634.590189-896.444377-232.737812z"
										fill="#E5EDF7" p-id="4776"></path>
									<path
										d="M1092.897811 901.062038l-45.82883 30.256302L196.453434 705.980377l290.197736-23.474717 606.246641 218.556378z"
										fill="#C9D5E4" p-id="4777"></path>
									<path
										d="M28.208302 314.483925l912.944302 203.157735 105.916377 413.657359L196.453434 705.980377z"
										fill="#D4DEEB" p-id="4778"></path>
									<path d="M281.001057 247.151094l-23.107623 119.672755L28.208302 314.483925z"
										fill="#BDCAD9" p-id="4779"></path>
									<path
										d="M1187.453585 450.540679l-246.300981 67.100981 105.916377 413.657359 45.82883-30.236981z"
										fill="#C8D4E3" p-id="4780"></path>
									<path
										d="M351.019472 43.143245l182.928905 31.415547-4.849509 26.121661-182.619774-31.49283zM344.411774 77.669434l183.469886 32.188377-3.265207 22.740529-184.745057-32.632755z"
										fill="#BCD3EA" p-id="4781"></path>
								</svg>
								<div>
									暂无文件
								</div>
							</div>
							<!-- 列表布局 -->
							<div class="tableBox" v-if="iconStatus == 0" v-loading="uploadLoading"
								element-loading-text="正在加载本地文件中..." element-loading-spinner="el-icon-loading"
								element-loading-background="rgba(0, 0, 0, 0.8)">
								<div class="grid">
									<el-table :data="fileDataList" style="width: 100%" height="calc('100% - 48px')"
										@selection-change="handleSelectionChange">
										<el-table-column type="selection" width="50">
										</el-table-column>
										<el-table-column width="40">
											<template slot-scope="scope">
												<img style="height: 23px; width: 23px;position: absolute;top:50%;transform: translateY(-50%);"
													:src="getImgSrc(scope.row)" alt="这是图片"
													@dblclick="openDir(scope.row)">
											</template>
										</el-table-column>
										<el-table-column prop="fileName" label="文件名称" min-width="240"
											show-overflow-tooltip></el-table-column>
										<el-table-column prop="fileSize" label="大小" width="240">
											<template slot-scope="scope">
												{{ formatFileSize(scope.row.fileSize) }}
											</template>
										</el-table-column>
										<el-table-column prop="suffix" label="扩展名" width="240"></el-table-column>
										<el-table-column label="创建日期" width="240">
											<template slot-scope="scope">{{ scope.row.createTime}}</template>
										</el-table-column>
										<el-table-column label="修改日期" width="240">
											<template slot-scope="scope">{{ scope.row.lastModifyTime }}</template>
										</el-table-column>
									</el-table>
								</div>
							</div>

							<!-- 格子布局 -->
							<div class="tableBox" v-if="iconStatus == 1" v-loading="uploadLoading"
								element-loading-text="正在加载本地文件中..." element-loading-spinner="el-icon-loading"
								element-loading-background="rgba(0, 0, 0, 0.8)">
								<div class="grid">
									<div class="grid-item" :class="{checked: item.checked}"
										v-for="(item,index) in fileDataList"
										@click="handleSelection(item, index, $event)" @dblclick="openDir(item)">
										<img :src="getImgSrc(item)">
										<span :title="item.fileName">{{ item.fileName }}</span>
										<!-- 如果是视频,则添加播放按钮 -->
										<div v-if="item.category === 1" class="play-button-overlay">
											<img style="width" src="/cloud/image/play.png" alt="Play Button">
										</div>
										<div v-if="item.description" class="movie-overlay">
											<svg t="1728482595265" class="icon" viewBox="0 0 1024 1024" version="1.1"
												xmlns="http://www.w3.org/2000/svg" p-id="6610" width="20" height="20">
												<path
													d="M928 960l-832 0C43.072 960 0 916.928 0 864l0-704C0 107.072 43.072 64 96 64l832 0C980.928 64 1024 107.072 1024 160l0 704C1024 916.928 980.928 960 928 960zM96 128C78.336 128 64 142.336 64 160l0 704C64 881.6 78.336 896 96 896l832 0c17.6 0 32-14.4 32-32l0-704C960 142.336 945.6 128 928 128L96 128z"
													fill="#999999" p-id="6611"></path>
												<path d="M64 320.064l895.872 0 0 64-895.872 0 0-64Z" fill="#999999"
													p-id="6612"></path>
												<path d="M192 128.064l64 0 0 192-64 0 0-192Z" fill="#999999"
													p-id="6613"></path>
												<path d="M384 128.064l64 0 0 192-64 0 0-192Z" fill="#999999"
													p-id="6614"></path>
												<path d="M576 128.064l64 0 0 192-64 0 0-192Z" fill="#999999"
													p-id="6615"></path>
												<path d="M768 128.064l64 0 0 192-64 0 0-192Z" fill="#999999"
													p-id="6616"></path>
												<path
													d="M438.208 519.36C400.32 500.416 384 519.552 384 561.984l0 153.728c0 42.368 16.32 61.504 54.208 42.496l162.88-85.12c37.888-19.008 41.472-49.92 3.712-68.672L438.208 519.36z"
													fill="#999999" p-id="6617"></path>
											</svg>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>

		<!-- 文件详情 -->
		<section class="nd-detail nd-main-list__detail">
			<div class="nd-detail__title">
				文件详情
			</div>

			<div v-if="this.fileDetail.length === 1">
				<!--选中一个文件的时候-->
				<div class="nd-detail__img bg">
					<img :src="getImgSrc(this.fileDetail[0])" class="category">
				</div>

				<div class="nd-detail__props">
					<div class="nd-detail__name">
						<span>{{ this.fileDetail[0].fileName }}</span>
					</div>
					<div v-if="this.fileDetail[0].createTime">
						<div class="prop_tittle">
							创建时间:
						</div>
						<div class="prop_text">
							{{ this.fileDetail[0].createTime }}
						</div>
					</div>
					<div v-if="this.fileDetail[0].lastModifyTime">
						<div class="prop_tittle">
							修改时间:
						</div>
						<div class="prop_text" v-if="this.fileDetail[0].lastModifyTime">
							{{ this.fileDetail[0].lastModifyTime }}
						</div>
					</div>
					<div v-if="this.fileDetail[0].suffix">
						<div class="prop_tittle">
							文件格式:
						</div>
						<div class="prop_text">
							{{ this.fileDetail[0].suffix }}
						</div>
					</div>
					<div v-if="this.fileDetail[0].isDir==0">
						<div class="prop_tittle">
							文件大小:
						</div>
						<div class="prop_text">
							{{ formatFileSize(this.fileDetail[0].fileSize) }}
						</div>
					</div>
					<div v-if="this.fileDetail[0].category==1||this.fileDetail[0].category==3">
						<div v-if="this.fileDetail[0].fileTime">
							<div class="prop_tittle">
								拍摄时间:
							</div>
							<div class="prop_text">
								{{ this.fileDetail[0].fileTime }}
							</div>
						</div>
						<div v-if="this.fileDetail[0].duration">
							<div class="prop_tittle">
								时长:
							</div>
							<div class="prop_text">
								{{ formatTime(this.fileDetail[0].duration) }}
							</div>
						</div>
						<div v-if="this.fileDetail[0].latitude">
							<div class="prop_tittle">
								纬度:
							</div>
							<div class="prop_text">
								{{ this.fileDetail[0].latitude }}
							</div>
						</div>
						<div v-if="this.fileDetail[0].longitude">
							<div class="prop_tittle">
								经度:
							</div>
							<div class="prop_text">
								{{ this.fileDetail[0].longitude }}
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="this.fileDetail.length > 1">
				<div class="nd-detail__empty">
					<img src="/cloud/image/file_type/folder.png" class="img">
					<p>共选中{{ this.fileDetail.length }}个文件</p>
				</div>
			</div>
		</section>

		<!-- 分享选项弹框 -->
		<el-dialog title="分享" :visible.sync="centerDialogVisible" width="400px" style="border-radius: 5px;" center>
			<div class="wp-share-file">
				<span>有效期： </span>
				<el-select v-model="validity" placeholder="请选择">

					<el-option v-for="item in validityData" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="wp-share-file">
				<span>是否加密： </span>
				<el-switch v-model="isEncrypt">
				</el-switch>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="centerDialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="shareFiles('share_list3')">分享相册</el-button>
				<el-button type="primary" @click="shareFiles('share_list')">直接分享</el-button>
			</span>
		</el-dialog>
		<!-- 分享结果弹框 -->
		<el-dialog title="分享" :visible.sync="shareFilesDialogVisible" width="30%" center>
			<div class="wp-share-file">
				<span style="color: #3a8ee6"><i class="el-icon-circle-check"></i>成功创建分享链接 </span>
			</div>
			<div class="wp-share-file">
				<el-input placeholder="分享链接" v-model="shareLink" :disabled="true">
				</el-input>
			</div>
			<div class="wp-share-file" v-if="isEncrypt === true">
				<span>提取码： </span>
				<el-input v-model="verificationCode" :disabled="true">
				</el-input>
			</div>
			<div class="wp-share-file"
				v-if="isEncrypt != true && multipleSelection.length === 1 && multipleSelection[0].isDir===0">
				<span>shell下载命令： </span>
				<el-input v-model="verificationCode" :disabled="true">
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="copyShareLink" v-if="isEncrypt === true">复制链接及提取码</el-button>
				<el-button type="primary" @click="copyShareLink1"
					v-if="isEncrypt != true"><strong>复制链接</strong></el-button>
				<!-- 无需提取码 -->
			</span>
		</el-dialog>

		<!-- 离线下载弹框  -->
		<el-dialog title="新建离线下载" :visible.sync="offlineDownloadDialogVisible" width="40%" class="off-file-dialog"
			center>
			<div class="wp-share-file">
				<textarea v-model="offlineDownloadLink" @input="getOfflineDownloadName" placeholder="请填写下载地址"
					class="off-file-textarea"></textarea>
			</div>
			<div style="margin: 15px 0;">
				保存位置：{{currentPathName}}
			</div>
			<div class="wp-share-file" v-if="isEncrypt === true">
				<span>文件名： </span>
				<el-input placeholder="文件名" v-model="offlineDownloadName">
				</el-input>
			</div>
			<div class="wp-share-file" v-if="isEncrypt === true">
				<span>文件大小： </span>
				<span>{{offlineDownloadSize}}</span>
			</div>


			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="addOfflineDownload" v-if="isEncrypt === true">加入离线下载</el-button>
			</span>
		</el-dialog>


		<!-- 文件上传列表 -->
		<div class="upload-list-dialog" id="uploadListDialog">
			<div class="dialog-header">
				<div @click="clearFileList">
					<span>清空</span>
				</div>
				<div>
					<span>上传列表</span>
					<i class="el-icon-arrow-down" id="hideButton" @click="hideButton" style="display: none"></i>
					<i class="el-icon-arrow-up" id="displayButton" @click="displayButton"></i>
				</div>
				<div style="margin-right: 10px;">
					<span>{{ completeFileSize }} / {{ totalSize }}</span>
				</div>
			</div>
			<div class="dialog-body">
				<div class="item">
					<div class="file-info">
						<div class="img">
							<span style="visibility: hidden">icon</span>
						</div>
						<div class="file-name">
							<span>文件名</span>
						</div>
						<div class="size">
							<span>大小</span>
						</div>
						<div class="size">
							<span>速度</span>
						</div>
						<div class="size">
							<span>剩余时间</span>
						</div>
						<div class="operation">
							<span>操作</span>
						</div>
					</div>
					<div class="el-progress file-progress" style="float: left; visibility: hidden">
						<el-progress :percentage="50" :show-text="false"></el-progress>
					</div>
				</div>
				<!-- 上传进度列表 -->
				<div class="item">
					<div class="file-info" v-for="(item, index) in fileList" :key="item.uid">
						<div class="img">
							<img :src="item.previewUrl" width="20" height="20">
						</div>
						<div class="file-name">
							<span>{{ item.name }}</span>
						</div>
						<div class="size">
							<span>{{ item.size }}</span>
						</div>
						<div class="size">
							<span>{{ item.speed }}</span>
						</div>
						<div class="size">
							<span>{{ item.leftTime }}</span>
						</div>
						<div class="operation">
							<i class="el-icon-close" @click="handleRemove(item)"></i>
						</div>
						<div class="el-progress file-progress" style="float: left; margin: 4px;">
							<el-progress :percentage="item.progress" :show-text="false" :stroke-width="4"></el-progress>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import MessageBox from '@/components/message-box'
	import {
		countingSpeed,
		deleteFile,
		getFileList,
		getFileFindList,
		searchByFields,
		createFolder,
		rename,
		editText,
	} from "../../../api/file";

	import {
		shareFiles
	} from "../../../api/share";

	import {
		addToMovie,
		removeOfMovie
	} from "../../../api/movie";


	import {
		getRequest,
		postRequest,
		getContentLong,
		singleUpload,
		enqueueUploadTask
	} from "../../../utils/http-request";
	import config from "../../../api/config";
	import axios from "axios";
	import {
		upload
	} from "../../../api/service";
	import {
		Message
	} from "element-ui";
	import router from "../../../router";
	import qs from 'qs';
	import Utils from "../../../assets/util";
	// import { forEach } from 'core-js/js/array';

	import {
		jsonp
	} from 'vue-jsonp';


	export default {
		name: "files",
		data() {
			return {
				editorInstance: null,
				//当前访问路径
				currentPathName: sessionStorage.getItem('path'), //当前节点
				multipleSelection: [], //选中文件
				selectStatus: 0, //0为选择，1为选中文件	
				pasteStatus: 0, //0为默认，1为进入粘贴状态
				pasteFileIdList: [], //需要粘贴的文件id列表
				previewStatus: 0, // 是否显示预览
				renameStatus: 0, //是否显示重命名按钮
				iconStatus: 1, //0列表页，1为图标
				keyword: '',
				dataForm: {
					path: this.currentPathName
				},
				fileDataList: [],
				dialogTableVisible: false,
				dialogFormVisible: false,
				form: {
					name: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				formLabelWidth: '120px',
				fileList: [],
				uploadState: false,
				loading: false,
				complete: false,
				// isShift: false,  // 快捷键 shift 是否被按下
				isCtrl: false, // 快捷键 ctrl 是否被按下
				selectedState: [], // 上方div图切是否被多选中，如果多选了就把他的id放到这个列表里面，当然可以不用，直接在相应的列表对象加一个参数isSelected也行，具体看项目和个人编码习惯哈。
				// shiftNum: '', // shift 按下后选择的第一个div下标，因为shift的时候要点两个div都中间包裹的都要切换为被选中状态
				uploadLoading: false, //上传加载动画
				fileDetail: [],
				// 通用预览状态
				preview: {
					fileId: '',
					visible: false, // 是否显示预览弹框
					type: '', // 预览类型：image, video, txt
					title: '', // 预览标题
					content: '', // 预览内容（URL 或文本内容）
					imgList: [],
					currentIndex: 0, // 当前预览的图片索引
				},
				imgViewerVisible: false,
				dialogPlay: false,
				dialogTxt: false,
				videoName: '', //当前预览的文件名
				txtFileName: '',
				txtContent: '',
				videoUrl: '', //预览视频地址
				dropActive: false, //拖拽上传
				centerDialogVisible: false, //分享弹窗
				validity: 1, //分享有效期
				validityData: [{ //分享有效期选项
					value: 1,
					label: '1天'
				}, {
					value: 7,
					label: '7天'
				}, {
					value: 30,
					label: '30天'
				}, {
					value: 365,
					label: '1年'
				}, {
					value: 365 * 100,
					label: '永久'
				}],
				isEncrypt: true, //分享是否加密
				shareFilesDialogVisible: false, //分享创建成功后弹窗
				offlineDownloadDialogVisible: false, //离线下载弹窗
				offlineDownloadLink: '', //离线下载链接
				offlineDownloadName: '', //离线下载文件名称
				offlineDownloadSize: '', //离线下载文件大小
				shareLink: '', //分享链接
				verificationCode: '', //提取码
				ignoreList: ['.DS_Store', '.svn', '.git'], //过滤文件名称列表
				debounceTimer: null, //防抖定时器
				completeFileSize: 0, //完成上传文件数量
				offlineDebounceTimer: null, // 离线文件防抖定时器
			}
		},
		components: {
			'el-image-viewer': () => import('../../../components/image/src/image-viewer')
		},
		mounted() {
			this.initGetFileList();

			// this.$refs.uploadInput.$children[0].$refs.input.webkitdirectory = true

			//拖拽上传
			let dropArea = document.getElementById('drop-area')
			//设置监听
			dropArea.addEventListener('drop', this.dropEvent, false)
			//拖动离开
			dropArea.addEventListener('dragleave', (e) => {
				e.stopPropagation()
				e.preventDefault()
				this.dropActive = false
			})
			//拖动进入
			dropArea.addEventListener('dragenter', (e) => {
				e.stopPropagation()
				e.preventDefault()
				this.dropActive = true
			})
			//拖动结束
			dropArea.addEventListener('dragover', (e) => {
				e.stopPropagation()
				e.preventDefault()
				this.dropActive = true
			})
		},
		computed: {
			selection() {
				return this.fileDataList.filter(item => item.checked)
			},
			totalSize() {
				return this.fileList.length; // 总数
			},
			uploadingSize() {
				return this.fileList.filter(file => file.complete).length; // 已完成的数量
			}

		},
		methods: {
			initGetFileList() {
				clearTimeout(this.debounceTimer); // 清除之前的定时器
				this.debounceTimer = setTimeout(() => {
					getFileList({
						path: this.currentPathName
					}, (res) => {
						if (res.code === 200 && res.success === true) {
							this.fileDataList = res.data;
						} else {
							Message.error(res.message);
						}
					});
				}, 500); // 设置防抖时间为1000毫秒（1秒）

				// let vm = this;
				// getFileList({
				// 	path: this.currentPathName
				// }, function(res) {
				// 	if (res.code === 200 && res.success === true) {
				// 		vm.fileDataList = res.data;
				// 	} else {
				// 		Message.error(res.message);
				// 	}
				// })
			},
			// 文件搜索
			getFile() {
				this.cancelSelection();
				if (!this.keyword) {
					this.currentPathName = "/";
					this.initGetFileList();
				} else {
					let data = {
						keyword: this.keyword,
						size:999
					}
					//文件搜索
					getFileFindList(data, (res) => {
						console.log('搜索结果', res);
						this.currentPathName = '搜索结果';
						this.fileDataList = res.data.content;
					})
				}
			},
			//切换布局
			changesIconStatus() {
				this.iconStatus = this.iconStatus === 0 ? 1 : 0;
				//清空选中文件
				this.selectedState = [];
				this.multipleSelection = [];
				this.fileDetail = [];

				//修改预览按钮
				this.previewStatus = 0;
				//修改重名按钮
				this.renameStatus = 0;
			},
			//选中文件
			handleSelectionChange(val) {
				this.multipleSelection = val;
				if (val.length === 1) {
					//2024-10-27 修改 只要选择了文件就显示预览按钮
					this.previewStatus = 1; //显示预览按钮

					// switch (val[0].category) {
					// 	case 1:
					// 	case 3:
					// 	case 4:
					// 	case 6:
					// 	case 8:
					// 		this.previewStatus = 1; //显示预览按钮
					// 		break;
					// }

					this.renameStatus = 1;
				} else {
					this.previewStatus = 0;
					this.renameStatus = 0;
				}
				if (val.length > 0) {
					this.selectStatus = 1;
					this.fileDetail = this.multipleSelection;
					this.selectedState = [];
					for (let i = 0; i < val.length; i++) {
						let str = val[i].fileId
						this.selectedState.push(str)
					}
				} else {
					this.selectStatus = 0;
					this.selectedState = []
					this.fileDetail = []
				}
			},
			//全部选中
			allSelection() {
				this.selectStatus = 1;
				this.selectedState = []
				this.fileDataList.forEach((item) => {
					item.checked = true
				})
				this.multipleSelection = this.fileDataList
				this.fileDetail = this.multipleSelection

			},
			//取消选中
			cancelSelection() {
				this.selectStatus = 0;
				this.selectedState = []
				this.fileDetail = []
				this.multipleSelection = []
				this.fileDataList.forEach((item) => {
					item.checked = false
				})
			},
			//判断组合键多选
			handleSelection(item, index, e) {
				this.isCtrl = (e.metaKey || e.ctrlKey);
				// this.isShift = e.shiftKey;

				if (this.isCtrl) { // 如果按下的是ctrl
					let str = item.fileId // 这行代码没必要，但是案例是根据我项目改的，就懒得删了
					let i = this.selectedState.indexOf(str) // 判断选中列表中是否包含这个点击的div

					if (i < 0) {
						this.selectedState.push(str); // 如果不包含就加进去
						this.multipleSelection.push(item);
						this.$set(item, 'checked', !item.checked);
					} else {
						this.selectedState.splice(i, 1); // 如果包含就删，表示按下ctrl键点一下选中，在点一下取消选中
						this.multipleSelection.splice(i, 1);
						this.$set(item, 'checked', false);
					}
					// } else if (this.isShift) {   // 如果按下的是shift
					//   if (this.shiftNum === '') {  // 如果还没点下第一个div
					//     this.shiftNum = index  // 让第一个div的下标赋值给shiftNum
					//     let str = item.fileId
					//     let i = this.selectedState.indexOf(str)
					//     if (i < 0) {
					//       this.selectedState.push(str)
					//       this.$set(item, 'checked', !item.checked);
					//     } else {
					//       this.selectedState.splice(i, 1);
					//       this.$set(item, 'checked', false)
					//     }
					//   } else {  // 如果点击第一个了
					//     if (this.shiftNum > index) {  // 选中第一个的索引大于当前点击的索引
					//       for (let j = index; j <= this.shiftNum; j++) {  // 把中间的都选中
					//         let str = this.fileDataList[j].fileId
					//         let i = this.selectedState.indexOf(str)
					//         if (i < 0) {
					//           this.selectedState.push(str)
					//         }
					//         this.$set(item, 'checked', !item.checked);
					//       }
					//     } else {
					//       for (let j = this.shiftNum; j <= index; j++) {
					//         let str = this.fileDataList[j].fileId
					//         let i = this.selectedState.indexOf(str)
					//         if (i < 0) {
					//           this.selectedState.push(str)
					//         }
					//         this.$set(item, 'checked', !item.checked);
					//       }
					//     }
					//   }
				} else {
					let str = item.fileId
					let i = this.selectedState.indexOf(str) // 判断选中列表中是否包含这个点击的div
					if (i < 0) { //不包含的情况下
						let lastCheckData = this.fileDataList.filter(item => item.checked); //获取上次选中
						if (lastCheckData.length > 0) { //如果上次有选中，就删掉，如果上次没有选中就不做判断
							for (let j = 0; j < lastCheckData.length; j++) {
								this.selectedState.splice(0, 1); //删掉上次选中
								this.multipleSelection.splice(0, 1);
								this.$set(lastCheckData[j], 'checked', false);
							}
						}

						this.selectedState.push(str) // 如果不包含就加进去
						this.multipleSelection.push(item);
						this.$set(item, 'checked', !item.checked);
					} else {
						this.selectedState.splice(i, 1); // 如果包含就删，表示点一下选中，再点一下取消选中
						this.multipleSelection.splice(i, 1);
						this.$set(item, 'checked', false);
					}
				}
				//选中的时候切换按钮
				if (this.selectedState.length > 0) {
					this.selectStatus = 1;
					this.fileDetail = this.multipleSelection
				} else {
					this.selectStatus = 0;
					this.fileDetail = []
				}

				if (this.selectedState.length === 1) {
					//2024-10-27 修改 只要选择了文件就显示预览按钮
					this.previewStatus = 1; //显示预览按钮

					// switch (this.multipleSelection[0].category) {
					// 	case 1:
					// 	case 3:
					// 	case 4:
					// 	case 6:
					// 	case 8:
					// 		this.previewStatus = 1; //显示预览按钮
					// 		break;
					// 	default:
					// 		this.previewStatus = 0; //默认隐藏预览按钮
					// 		break;
					// }


					this.renameStatus = 1;
				} else {
					this.previewStatus = 0;
					this.renameStatus = 0;
				}
			},
			sharedFile() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					postRequest(config.url.sharedFile + this.multipleSelection[0].fileId, {}).then(res => {
						if (res.code === 200 && res.success === true) {
							Message.success(res.message);
							// location.reload();
							this.initGetFileList();
							//清空选中文件
							this.selectedState = [];
							this.multipleSelection = [];
							this.fileDetail = [];
						} else {
							Message.error(res.message);
						}
					})
				}
			},
			cancelShared() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					postRequest(config.url.cancelShared + this.multipleSelection[0].fileId, {}).then(res => {
						if (res.code === 200 && res.success === true) {
							Message.success(res.message);
							// location.reload();
							this.initGetFileList();
							//清空选中文件
							this.selectedState = [];
							this.multipleSelection = [];
							this.fileDetail = [];
						} else {
							Message.error(res.message);
						}
					})
				}
			},
			//时间格式转换
			formatTime(duration) {
				if (duration.indexOf(":") > -1) {
					return duration;
				}
				if (!duration && duration !== 0) return '00:00:00';
				const hours = Math.floor(duration / 3600);
				const minutes = Math.floor((duration - (hours * 3600)) / 60);
				const seconds = Math.floor(duration - (hours * 3600) - (minutes * 60));
				const milliseconds = Math.round((duration - Math.floor(duration)) * 1000); // 将小数部分转为毫秒

				return (hours < 10 ? '0' : '') + hours + ':' +
					(minutes < 10 ? '0' : '') + minutes + ':' +
					(seconds < 10 ? '0' : '') + seconds + '.' +
					(milliseconds < 100 ? (milliseconds < 10 ? '00' : '0') : '') + milliseconds;
			}

			,
			// 文件大小格式化
			formatFileSize(fileSize) {
				if (fileSize <= 0) {
					return '0KB';
				}
				let k = 1024,
					units = ['B', 'KB', 'MB', 'GB', 'TB'],
					i = Math.floor(Math.log(fileSize) / Math.log(k));
				return parseFloat((fileSize / Math.pow(k, i)).toFixed(2)) + units[i];
			},
			getPreviewUrl(file) {
				// 获取文件类型
				let fileType = file.type;
				// 获取文件扩展名
				let extension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();

				// 判断文件是否为图片类型
				let isImage = fileType.startsWith('image/') || ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'ico']
					.includes(
						extension);

				// 如果是图片，生成本地预览 URL
				let previewUrl = '';
				if (isImage) {
					previewUrl = URL.createObjectURL(file);
				} else {
					previewUrl = this.getUploadImgSrc(extension);
				}
				return previewUrl;
			},
			getUploadImgSrc(extension) {
				// 根据扩展名返回对应的图标路径
				let fileType = {
					music: 'music',
					txt: 'txt',
					tar: 'zip',
					tgz: 'zip',
					gz: 'zip',
					rar: 'zip',
					zip: 'zip',
					h: 'c',
					c: 'c',
					cpp: 'c',
					hpp: 'c',
					m: 'code',
					bas: 'code',
					prg: 'code',
					cmd: 'bat',
					kt: 'code',
					rs: 'code',
					asp: 'aspx',
					htm: 'html',
					html: 'html',
					properties: 'conf',
					doc: 'doc',
					docx: 'doc',
					xls: 'xls',
					xlsx: 'xls',
					csv: 'xls',
					ppt: 'ppt',
					pptx: 'ppt',
					torrent: 'torrent',
				};
				let iconName = fileType[extension];
				if (!iconName) {
					iconName = 'other';
				}
				return `/cloud/image/file_type/${iconName}.png`;
			},
			// 文件图标获取
			getImgSrc(item) {
				const {
					isDir,
					suffix,
					category,
					fileId,
					shared,
					description
				} = item;

				// 图标映射
				const iconMap = {
					0: suffix,
					2: "music",
					4: "txt",
					5: suffix,
					6: {
						"tar": "zip",
						"tgz": "zip",
						"gz": "zip",
						"rar": "zip",
						"zip": "zip"
					},
					7: "torrent",
					8: {
						"h": "c",
						"c": "c",
						"cpp": "c",
						"hpp": "c",
						"m": "code",
						"bas": "code",
						"prg": "code",
						"cmd": "bat",
						"kt": "code",
						"hpp": "c",
						"rs": "code",
						"asp": "aspx",
						"htm": "html",
						"properties": "conf"
					},
					9: "other",
					10: {
						"doc": "doc",
						"docx": "doc",
						"xls": "xls",
						"xlsx": "xls",
						"csv": "xls",
						"ppt": "ppt",
						"pptx": "ppt"
					},
				};

				//以下类型 读取缩略图
				if (category === 1 || category === 3 || suffix == 'pdf' || suffix == 'ppt' || suffix == 'pptx' || suffix ==
					'webp') {
					if (category === 1) {
						const desc = JSON.parse(description);
						if (desc && desc.images) {
							return desc.images;
						}

					}
					if (suffix == 'webp') {
						return config.url.file.downloadFile + fileId;
					}

					return config.url.file.getImgUrl + fileId;
				}

				//如果是文件夹则显示图标
				if (isDir == 1) {
					// return require("/public/image/file_type/" + (shared ? "share_folder" : "folder") + ".png");
					return `/cloud/image/file_type/${shared ? 'share_folder' : 'folder'}.png`;

				}

				let icon = iconMap[category];
				if (typeof icon === "object") {
					icon = icon[suffix] || suffix;
				}

				// return require("/public/image/file_type/" + icon + ".png");
				return `/cloud/image/file_type/${icon}.png`;

			},

			//获取文件目录结构树
			async getFileTree(item) {
				let that = this;
				var path = item.fullPath || "";
				let dir = new Array();
				if (item.isFile) {
					let resFile = await this.fileSync(item);
					resFile.path = path;
					return resFile;
					// item为文件夹时
				} else if (item.isDirectory) {
					var dirReader = item.createReader();
					let entries = await that.readEntriesSync(dirReader);
					for (let i = 0; i < entries.length; i++) {
						let proItem = await that.getFileTree(entries[i]);
						dir.push(proItem);
					}
					return dir;
				}
			},
			/**
			 * 展平数组
			 * @param {Array} 需要展平的数组
			 * @param {Array} 展平后的数组
			 *
			 */
			flattenArray(array, result) {
				// console.log(array, flatArray);
				for (let i = 0; i < array.length; i++) {
					if (Array.isArray(array[i])) {
						this.flattenArray(array[i], result);
					} else {
						result.push(array[i]);
					}
				}
			},
			/**
			 * 获取文件
			 */
			fileSync(item) {
				return new Promise((resolve, reject) => {
					item.file(res => {
						resolve(res);
					});
				});
			},
			//读取文件夹下的文件
			readEntriesSync(dirReader) {
				return new Promise((rel, rej) => {
					dirReader.readEntries(res => {
						rel(res);
					});
				});
			},
			//拖拽上传事件回调
			async dropEvent(e) {
				this.dropActive = false

				//阻止事件冒泡
				e.stopPropagation();
				//阻止事件的默认行为
				e.preventDefault();
				//储存获取到的文件列表
				let fileList = [];
				let DirectoryEntryList = [];

				if (e.dataTransfer.items) {
					// 拖拽对象列表转换成数组
					let items = new Array(...e.dataTransfer.items);
					// 获得DirectoryEntry对象列表
					for (let index = 0; index < items.length; index++) {
						let e = items[index];
						let item = null;
						//兼容不同内核的浏览器
						if (e.webkitGetAsEntry) {
							item = e.webkitGetAsEntry();
						} else if (e.getAsEntry) {
							item = e.getAsEntry();
						} else {
							this.$alert("浏览器不支持拖拽上传", "提示");
							return;
						}
						DirectoryEntryList.push(item);
					}
					if (DirectoryEntryList.length > 0) {
						for (let index = 0; index < DirectoryEntryList.length; index++) {
							let item = DirectoryEntryList[index];
							if (item) {
								//获取文件夹目录
								let FileTree = await this.getFileTree(item);
								// 拿到目录下的所有文件
								if (Array.isArray(FileTree)) {
									//展平文件夹
									this.flattenArray(FileTree, fileList);
								} else {
									//方便后续处理，单文件时也包装成数组
									fileList.push(FileTree);
								}
							}
						}
					}
				}

				let files = [];
				//修改文件名为路径
				fileList.forEach((fileItem) => {
					//过滤文件
					if (this.ignoreList.indexOf(fileItem.name) < 0) {
						//添加文件
						files.push(new File([fileItem], fileItem.path));
					}
				})
				//console.log(files);
				this.dropUploadFile(files)
			},

			dropUploadFile(fileList) {
				this.fileListUpload(fileList, this.currentPathName, function(result) {
					if (result.code === '200') {
						let vm = this;
						getFileList(vm.dataForm, function(res) {
							if (res) {
								vm.fileDataList = res.data;
								this.$message.success(result.msg);
							}
						})
					} else {
						this.$message.info(result.msg);
					}
				}, 0);
			},

			//点击按钮 上传文件列表
			uploadFile(event) {
				let fileList = event.target.files;

				this.fileListUpload(fileList, this.currentPathName, function(result) {
					if (result.code === '200') {
						let vm = this;
						getFileList(vm.dataForm, function(res) {
							if (res) {
								vm.fileDataList = res.data;
								this.$message.success(result.msg);
							}
						})
					} else {
						this.$message.info(result.msg);
					}
				}, 0);
			},

			//点击上传文件
			toUpload() {
				document.getElementById('upload-input').click()
			},
			//点击上传文件夹
			toUploadDirectory() {
				document.getElementById('upload-input2').click()
			},
			//离线下载文件
			offlineDownload() {
				this.offlineDownloadName = '';
				this.offlineDownloadLink = '';
				this.offlineDownloadSize = '0KB';
				//显示弹框
				this.offlineDownloadDialogVisible = true;
			},
			//获取路径中的文件名
			getOfflineDownloadName() {
				// 清除之前的定时器
				if (this.offlineDebounceTimer) {
					clearTimeout(this.offlineDebounceTimer);
				}

				// 设置新的定时器
				this.offlineDebounceTimer = setTimeout(() => {
					if (!this.offlineDownloadLink.startsWith('http')) {
						this.$message.info('请填写正确的下载地址');
						return;
					}
					this.offlineDownloadName = decodeURI(this.offlineDownloadLink.substring(this
						.offlineDownloadLink.lastIndexOf("/") + 1));
					this.getOfflineFileSize(this.offlineDownloadLink);
				}, 1000); // 300ms 的防抖时间
			},
			//获取离线文件大小，使用服务器发送请求，避免跨域问题
			async getOfflineFileSize(url) {
				getRequest(config.url.file.fileSizeFromUrl + '?url=' + url, (res) => {
					if (res.code === 200 && res.success === true && res.data) {
						this.offlineDownloadSize = this.formatFileSize(res.data);
					} else {
						this.offlineDownloadSize = '0KB';
					}
				})
			},
			//加入离线下载
			addOfflineDownload() {
				if (this.offlineDownloadLink === '') {
					this.$message.error('填写下载地址！');
				} else if (this.offlineDownloadName === '') {
					this.$message.error('填写文件名！');
				} else {
					let dataForm = {
						"url": this.offlineDownloadLink,
						"name": this.offlineDownloadName,
						"path": this.currentPathName,
					};
					this.$confirm('此操作将文件下载到当前目录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						postRequest(config.url.file.offlineFileDownload, dataForm, (res) => {
							Message.success(res.message);
							//关闭离线下载框
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消下载'
						});
					});
				}

			},
			//文件上传列表伸缩
			hideButton() {
				document.getElementById("uploadListDialog").style.bottom = "-255px";
				document.getElementById("hideButton").style.display = "none";
				document.getElementById("uploadListDialog").style.height = "0";
				document.getElementById("displayButton").style.removeProperty("display");
				document.getElementById("uploadListDialog").style.removeProperty("width");
				document.getElementById("uploadListDialog").style.removeProperty("min-width");
			},
			displayButton() {
				document.getElementById("uploadListDialog").style.bottom = "0px";
				document.getElementById("uploadListDialog").style.width = "25%";
				document.getElementById("uploadListDialog").style.height = "50%";
				document.getElementById("uploadListDialog").style.minWidth = "600px";
				document.getElementById("displayButton").style.display = "none";
				document.getElementById("hideButton").style.removeProperty("display");
			},

			//文件上传
			fileListUpload(fileList, path, callback, imageIndex) {
				this.uploadLoading = true;

				let param = {
					path: path,
					filesName: Array.from(fileList).map((file) => file.name),
				};

				// 创建新的文件列表条目，包含上传状态等信息
				let newFileListEntries = Array.from(fileList).map((file) => ({
					uid: `${Date.now()}_${Math.random()}`,
					name: file.name,
					size: this.formatFileSize(file.size),
					sizeInBytes: file.size,
					speed: '0B/s',
					leftTime: '-',
					progress: 0,
					uploadedSize: 0,
					isCompleted: false,
					file: file,
					previewUrl: this.getPreviewUrl(file), // 图片预览 URL
				}));


				// 将新文件追加到现有的 this.fileList 中
				this.fileList = this.fileList.concat(newFileListEntries);

				// 没有同名文件，正常上传
				this.uploadLoading = false;
				newFileListEntries.forEach((item) => (item.file.uploadType = 1));
				this.uploadFileAjax(newFileListEntries, path, callback, imageIndex);

				// 检查是否有同名文件
				// postRequest(config.url.file.checkIsHave, param, (result) => {
				// 	this.uploadLoading = false;
				// 	if (result.code === 200 && parseInt(result.message) > 0) {
				// 		MessageBox.confirm('目标包含' + result.message + ' 个同名文件', {
				// 				confirmButtonText: '替换所有',
				// 				cancelButtonText: '保留重复',
				// 				type: 'info',
				// 				center: true,
				// 				showCancelButton: true,
				// 			})
				// 			.then(() => {
				// 				// 设置上传类型为替换
				// 				newFileListEntries.forEach((item) => (item.file.uploadType = 1));
				// 				this.uploadFileAjax(newFileListEntries, path, callback, imageIndex);
				// 			})
				// 			.catch(() => {
				// 				// 设置上传类型为保留重复
				// 				newFileListEntries.forEach((item) => (item.file.uploadType = -1));
				// 				this.uploadFileAjax(newFileListEntries, path, callback, imageIndex);
				// 			});
				// 	} else {
				// 		// 没有同名文件，正常上传
				// 		newFileListEntries.forEach((item) => (item.file.uploadType = 0));
				// 		this.uploadFileAjax(newFileListEntries, path, callback, imageIndex);
				// 	}
				// });
			},

			async uploadFile2(url, formData) {
				try {
					const response = await axios.post(url, formData);
					console.log(response);
				} catch (error) {
					console.error(error);
				}
			},
			// 文件上传
			uploadFileAjax(fileItems, path) {
				document.getElementById("uploadListDialog").style.bottom = "0px";
				document.getElementById("uploadListDialog").style.width = "25%";
				document.getElementById("uploadListDialog").style.height = "50%";
				document.getElementById("uploadListDialog").style.minWidth = "600px";
				document.getElementById("displayButton").style.display = "none";
				document.getElementById("hideButton").style.removeProperty("display");



				fileItems.forEach((fileItem) => {
					if (fileItem.name == '.' || fileItem.name == '.DS_Store') {
						return;
					}

					this.loading = true;
					this.complete = false;
					this.uploading = true;

					// 获取文件临时uid			
					let fileUid = fileItem.uid;
					const updateProgressCallback = (uid, progress) => {
						let item = this.fileList.find((item) => item.uid === uid);
						if (item) {
							item.progress = progress;
							item.uploadedSize = item.sizeInBytes * (progress / 100);

							// 计算速度和剩余时间，直接将 item 传递
							const countingList = countingSpeed(item);
							if (countingList) { // 只有在计算有效时更新
								item.speed = countingList.speed;
								item.leftTime = countingList.leftTimeStr;
							}
							if (progress == 100) {
								item.speed = '加密中';
								item.leftTime = '请稍后';
							}

							this.uploadState = false;
						} else {
							console.warn('未找到匹配的文件项，UID:', uid);
						}
					};

					enqueueUploadTask(() =>
						singleUpload(
							fileItem.file,
							path,
							fileUid,
							updateProgressCallback,
							(res) => {
								let item = this.fileList.find((item) => item.uid === fileUid);
								if (item) {
									item.progress = 100;
									item.uploadedSize = item.sizeInBytes;
									item.speed = '已完成';
									item.leftTime = '-';
									item.isCompleted = true;
									// 记录完成时间
									item.completionTime = new Date().getTime();
									this.uploadState = true;

									this.loading = false;
									this.complete = true;
									this.uploading = false;
									this.completeFileSize++;
									this.initGetFileList();
									// 按照新的要求对文件列表进行排序
									this.sortFileList();
								}
							},
							(res) => {
								this.loading = false;
								this.complete = false;
								this.uploading = false;
								this.completeFileSize++;
							}, (cancelFunc) => {
								// 保存取消函数到文件项中
								fileItem.cancelToken = cancelFunc;
							}
						)
					);
				});

			},
			sortFileList() {
				this.fileList.sort((a, b) => {
					if (a.isCompleted === b.isCompleted) {
						if (!a.isCompleted) {
							// 两个文件都在上传中，保持原有顺序
							return 0;
						} else {
							// 两个文件都已完成，按照完成时间倒序排列
							return b.completionTime - a.completionTime;
						}
					} else {
						// 正在上传的文件排在前面
						return a.isCompleted - b.isCompleted;
					}
				});
			},
			clearFileList() {
				if (this.fileList.filter(file => file.uploading).length > 0) {
					Message.warning({
						message: "文件正在上传中，无法清空"
					});
				} else {
					this.fileList = [];
					this.completeFileSize = 0;
				}
			},
			//下载文件
			downloadFile() {
				if (this.multipleSelection.length === 0) {
					this.$message.error('请选择文件!');
				} else if (this.multipleSelection.length === 1 && this.multipleSelection[0].isDir === 0) {
					let file = this.multipleSelection[0];

					const link = document.createElement('a');
					link.href = `${config.url.file.downloadFile}${file.fileId}`;
					link.setAttribute('download', file.fileName);
					document.body.appendChild(link);
					link.click();

					//如果是单个文件
					/*axios({
						url: `${config.url.downloadFile}${file.fileId}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.fileName);
						document.body.appendChild(link);
						link.click();
					});*/

				} else {
					//如果是文件夹 或者 多个文件，则启动压缩下载
					let fileIdList = [];
					for (let i = 0; i < this.multipleSelection.length; i++) {
						const file = this.multipleSelection[i];
						fileIdList.push(file.fileId);
					}

					let zipName = this.multipleSelection[0].fileName;
					if (fileIdList.length > 1) {
						zipName += "等多个文件";
					}


					axios({
						// url: `${config.url.folderDownload}${file.fileId}`,
						url: `${config.url.file.downloadFileZIP}?zipName=${zipName}&fileIdList=${fileIdList}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						let blob = new Blob([response.data], {
							type: 'application/zip'
						})
						let url = window.URL.createObjectURL(blob)
						const link = document.createElement('a')
						link.href = url
						link.download = zipName + '.zip';
						link.click()
						URL.revokeObjectURL(url)
					});
				}
			},

			//打包下载文件
			downloadFileZIP() {
				if (this.multipleSelection.length === 0) {
					this.$message.error('请选择文件!');
				} else {
					let fileIdList = [];
					for (let i = 0; i < this.multipleSelection.length; i++) {
						const file = this.multipleSelection[i];
						fileIdList.push(file.fileId);
					}

					axios({
						url: `${config.url.file.downloadFileZIP}?zipName=abc&fileIdList=${fileIdList}`,
						method: 'GET',
						responseType: 'blob', // important
					}).then((response) => {
						const url = window.URL.createObjectURL(new Blob([response.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', file.fileName);
						document.body.appendChild(link);
						link.click();
					});
				}

			},

			//创建文件夹
			createFolderConfirm() {
				this.$prompt('请输入文件夹名称', '创建文件夹', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputPattern: /\S/,
					inputErrorMessage: '文件名不能为空'
				}).then(({
					value
				}) => {
					let data = {
						"path": this.currentPathName,
						"folderName": value
					};
					//先判断文件夹名是否已经存在
					// postRequest(config.url.checkFolderIsHave, data).then(res => {
					//   if (res.code === 200 && res.data === 0) {
					//
					//   } else {
					//     Message.error(res.message);
					//   }
					// })
					const that = this;
					createFolder(data, function(result) {
						debugger
						if (result.code === 200) {
							that.initGetFileList();
							Message.success('新建成功！');
						} else {
							Message.error('新建失败');
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '取消新建'
					});
				});
			},

			//重命名
			rename() {
				if (this.multipleSelection.length === 0) {
					this.$message.error('请选择文件！');
				} else if (this.multipleSelection.length > 1) {
					this.$message.error('请选择单个文件！');
				} else {
					this.$prompt('请输入新文件名', '重命名', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						inputPattern: /\S/,
						inputErrorMessage: '文件名不能为空',
						inputValue: this.multipleSelection[0].fileName
					}).then(({
						value
					}) => {
						if (value === this.multipleSelection[0].fileName) {
							Message.error('文件名未作修改，请重新输入！');
						} else {
							let dataFrom = {
								"fileName": value,
								"fileId": this.multipleSelection[0].fileId
							};
							rename(dataFrom, (res) => {
								if (res.code === 200) {
									Message.success("修改成功！");
									this.initGetFileList();
									//清空选中文件
									this.selectedState = [];
									this.multipleSelection = [];
									this.fileDetail = [];
								} else {
									Message.error(res.message);
								}
							});
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '取消重命名'
						});
					});
				}
			},
			//删除选中文件
			deleteFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						"fileIds": this.selectedState
					};
					this.$confirm('此操作将文件移动到回收站, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						deleteFile(dataForm, (res) => {
							if (res.code === 200 && res.success === true) {
								Message.success(res.message);
								// location.reload();
								this.initGetFileList();
								//清空选中文件
								this.selectedState = [];
								this.multipleSelection = [];
								this.fileDetail = [];
							} else {
								Message.error(res.message);
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			//复制选中文件
			copyFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						"fileIds": this.selectedState
					};
					this.$confirm('此操作将文件移动到回收站, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						postRequest(config.url.deleteFile, dataForm).then(res => {
							if (res.code === 200 && res.success === true) {
								Message.success(res.message);
								// location.reload();
								this.initGetFileList();
								//清空选中文件
								this.selectedState = [];
								this.multipleSelection = [];
								this.fileDetail = [];
							} else {
								Message.error(res.message);
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			//移动选中文件
			moveFiles() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					this.pasteStatus = 1;
					//保存选中的文件
					this.selectedState.forEach((item) => {
						this.pasteFileIdList.push(item);
					})
				}
			},
			//取消移动
			cencleMove() {
				this.pasteStatus = 0;
				this.pasteFileIdList = [];
			},
			//粘贴文件
			pasteFiles() {
				if (this.pasteFileIdList.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						"fileIds": this.pasteFileIdList,
						"path": this.currentPathName,
					};
					this.$confirm('此操作将文件移动当前目录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {

						postRequest(config.url.file.moveFiles, dataForm, res => {
							if (res.code === 200 && res.success === true) {
								Message.success(res.message);
								// location.reload();
								this.initGetFileList();
								Utils.$emit('getMemory');
								//清空选中文件
								this.selectedState = [];
								this.multipleSelection = [];
								this.fileDetail = [];
								this.pasteStatus = 0;
								this.pasteFileIdList = [];
							} else {
								Message.error(res.message);
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除'
						});
					});
				}
			},
			//添加到影视库
			addToMovie() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
					return;
				}

				let dataForm = {
					"fileId": this.selectedState[0]
				};
				addToMovie(dataForm, (res) => {
					const isSuccessful = res.code === 200 && res.success === true;
					if (isSuccessful) {
						Message.success("添加成功！");
						//更新操作的对象	
						this.initGetFileList();
						this.cancelSelection()
					} else {
						Message.error(res.message);
					}
				});
			},
			//从影视库移出
			removeOfMovie() {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
					return;
				}

				let dataForm = {
					"fileId": this.selectedState[0]
				};

				removeOfMovie(dataForm, (res) => {
					const isSuccessful = res.code === 200 && res.success === true;
					if (isSuccessful) {
						Message.success("成功移出！");
						//更新操作的对象
						this.initGetFileList();
						this.cancelSelection()
					} else {
						Message.error(res.message);
					}
				});
			},
			//分享
			shareFiles(pageType) {
				if (this.selectedState.length === 0) {
					this.$message.error('请选择文件！');
				} else {
					let dataForm = {
						"fileIds": this.selectedState,
						"isEncrypt": this.isEncrypt,
						"validity": this.validity
					};

					shareFiles(dataForm, (res) => {
						const isSuccessful = res.code === 200 && res.success === true;
						if (isSuccessful) {
							Message.success("创建成功！");
							this.centerDialogVisible = false; // 关闭窗口
							this.shareLink =
								`${window.location.origin}/cloud${config.url.share.getShareFiles}${res.data.shareId}?pageType=${pageType}`;
							this.shareFilesDialogVisible = true;

							if (this.isEncrypt) {
								this.verificationCode = res.data.code;
							} else if (this.multipleSelection.length === 1) {
								this.verificationCode =
									`wget -O ${this.multipleSelection[0].fileName} ${this.shareLink}`;
							} else {
								this.verificationCode = '';
							}
						} else {
							Message.error(res.message);
						}
					});

				}
			},

			//复制验证码及其提取码
			copyShareLink() {
				var copyTest = '链接：' + this.shareLink + ' 提取码：' + this.verificationCode;
				var inputTest = document.createElement('input');
				inputTest.value = copyTest;
				document.body.appendChild(inputTest);
				inputTest.select();
				document.execCommand("Copy");
				inputTest.className = 'oInput';
				inputTest.style.display = 'none';
				Message.success('复制成功！');
			},

			copyShareLink1() {
				let copyTest = '';
				if (this.multipleSelection.length === 1 && this.multipleSelection[0].isDir === 0) {
					copyTest = '链接：' + this.shareLink + ' shell下载命令：' + this.verificationCode;
				} else {
					copyTest = this.shareLink;
				}
				let inputTest = document.createElement('input');
				inputTest.value = copyTest;
				document.body.appendChild(inputTest);
				inputTest.select();
				document.execCommand("Copy");
				inputTest.className = 'oInput';
				inputTest.style.display = 'none';
				Message.success('复制成功！');
			},

			//双击打开文件夹
			openDir(item) {
				if (item.isDir === 1) {
					//清空选中文件
					this.selectedState = [];
					this.multipleSelection = [];
					this.fileDetail = [];
					//修改预览按钮
					this.previewStatus = 0;
					//修改重名按钮
					this.renameStatus = 0;
					//2024-10-25 修改路径拼接逻辑，修改后可以同时兼容搜索后的文件打开 this.currentPathName = this.currentPathName + item.fileName + "/";
					this.currentPathName = item.parentPath + item.fileName + "/";
					window.sessionStorage.setItem('path', this.currentPathName);
					this.initGetFileList();
				} else if (item.category === 3) { //双击预览图片
					// let imgUrl = config.url.file.downloadFile + item.fileId;
					// this.imgList.push(imgUrl);
					// this.showImgViewer();


					let imgUrl = config.url.file.downloadFile + item.fileId;
					this.preview.type = 'image';
					this.preview.imgList.push(imgUrl);
					this.preview.content = imgUrl; // 图片 URL
					this.preview.title = item.fileName;
					this.preview.visible = true;
				} else if (item.category === 1) { //双击预览视频
					// this.videoName = item.fileName;
					// this.videoUrl = config.url.file.preview + item.fileId;
					// this.playVideo();
					this.preview.type = 'video';
					this.preview.content = config.url.file.preview + item.fileId; // 视频 URL
					this.preview.title = item.fileName;
					this.preview.visible = true;
				} else if (item.category === 4 || item.category === 8) {
					this.preview.fileId = item.fileId;
					this.preview.type = 'txt';
					this.preview.content = '';
					this.preview.title = item.fileName;
					this.fetchTxtContent(item); // 加载文本内容
					this.preview.visible = true;
				} else {
					switch (item.category) {
						case 0: // 预览设计文件 psd等
						case 2: // 预览 音频						
						case 6: // 预览 压缩包
						case 10: // 双击预览 office
							if (item.suffix == 'other')
								return;

							var officeUrl = config.url.file.filePreview + item.fileId + "?fullfilename=" + item.fileName;
							window.open('http://moyi.chengxy.cn:8012/onlinePreview?url=' + window.btoa(
								encodeURIComponent(
									officeUrl)), "_blank");
							break;
					}
				}
			},

			//预览按钮
			previewImg() {
				this.openDir(this.multipleSelection[0]);
			},
			// 加载文本文件内容
			fetchTxtContent(item) {
				getFileFindList({
					fileId: item.fileId
				}, (res) => {
					if (res.code === 200 && res.success === true) {
						// 将文本内容加载到预览框
						this.preview.content = res.data.content[0].content;
					} else {
						this.$message.error('加载文本失败');
						console.error(error);
					}
				})
			},
			// 关闭预览
			closePreview() {
				this.preview.visible = false;
				this.preview.fileId = '';
				this.preview.type = '';
				this.preview.title = '';
				this.preview.content = '';
				this.preview.imgList = [];
			},
			previewTxtFile() {
				this.dialogTxt = true;
			},
			showImgViewer() { //预览图片
				this.imgViewerVisible = true;
				const m = (e) => {
					e.preventDefault()
				};
				document.body.style.overflow = 'hidden';
				document.addEventListener("touchmove", m, false); // 禁止页面滑动
			},
			closeImgViewer() { //关闭预览图片
				this.imgViewerVisible = false;
				const m = (e) => {
					e.preventDefault()
				};
				document.body.style.overflow = 'auto';
				document.removeEventListener("touchmove", m, true);
				this.imgList = [];
			},
			saveTxtFile() {
				// 保存逻辑
				editText({
					fileId: this.preview.fileId,
					content: this.preview.content
				}, (res) => {
					if (res.code === 200 && res.success === true) {
						this.$message.success('文件保存成功！');
					} else {
						this.$message.error('保存失败');
						console.error(error);
					}
				});
				this.closePreview();
			},
			viewOffice() { //预览office文件
				this.dialogPlay = true;
			},

			closeViewOffic() {
				this.office = "" //清空数据 关闭预览office
			},

			//上一页
			lastPage() {
				//清空选中文件
				this.selectedState = [];
				this.multipleSelection = [];
				this.fileDetail = [];
				//修改预览按钮
				this.previewStatus = 0;
				//修改重名按钮
				this.renameStatus = 0;
				if (this.currentPathName.endsWith("/")) {
					this.currentPathName = this.currentPathName.substring(0, this.currentPathName.lastIndexOf(
						'/'));
					window.sessionStorage.setItem('path', this.currentPathName);
				}
				this.currentPathName = this.currentPathName.substring(0, this.currentPathName.lastIndexOf(
					'/'));
				this.currentPathName = this.currentPathName + "/";
				window.sessionStorage.setItem('path', this.currentPathName);
				this.initGetFileList();
			},
			//全部文件
			firstPage() {
				//清空选中文件
				this.selectedState = [];
				this.multipleSelection = [];
				this.fileDetail = [];
				//修改预览按钮
				this.previewStatus = 0;
				//修改重名按钮
				this.renameStatus = 0;
				this.currentPathName = "/";
				window.sessionStorage.setItem('path', this.currentPathName);
				this.initGetFileList();
			},
			handleRemove(item) {
				if (!item.isCompleted) {
					// 文件正在上传中，弹出确认对话框
					this.$confirm('此操作将取消文件上传，是否继续？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning',
						})
						.then(() => {
							// 用户确认取消上传
							this.cancelUpload(item);
						})
						.catch(() => {
							// 用户取消操作
							this.$message({
								type: 'info',
								message: '已取消操作',
							});
						});
				} else {
					// 文件已经上传完成，直接移除文件项
					this.removeFileItem(item);
				}
			},
			// 取消上传任务
			cancelUpload(item) {
				// 调用取消上传的方法
				if (item.cancelToken) {
					item.cancelToken(); // 取消请求
				}
				// 从文件列表中移除该文件项
				this.removeFileItem(item);
			},
			// 移除文件项
			removeFileItem(item) {
				// 从 fileList 中移除该文件项
				this.fileList = this.fileList.filter((file) => file.uid !== item.uid);
			},

		}
	}
</script>

<style>
	.files-right-count {
		font-size: 1rem;
	}

	.files-main-list {
		height: 100%;
		padding: 18px 24px 0 18px;
	}

	.btn-group__wrapper {
		display: inline-block;
		vertical-align: middle;
	}

	.btn-search__wrapper {
		min-width: 280px;
	}

	.pc-search__wrapper {
		border: 1px solid #c5c5c5;
		border-right: 0;
		border-radius: 20px 0 0 20px;
		background: #f4f4f4;
		height: 40px;
		width: 80%;
		line-height: 30px;
		border: none;
		background-color: transparent;
		border: none;
		background-color: transparent;
		border: 1px solid #D5E3E8;
		background: #fff;
		padding: 5px 10px;
	}

	.pc-search__wrapper_button {
		height: 40px;
		width: 20%;
		border-radius: 0 20px 20px 0 !important;
		border-left: 0;
		padding: 5px 15px;
	}

	.files-list-toolbar__wrapper {
		border-radius: 4px 4px 0 0;
		height: 40px;
		line-height: 40px;
		border-bottom-width: 0;
		overflow: hidden;
		padding: 6px 14px 0 12px;
		width: calc(100% - 20px);
	}

	.files-list-left__wrapper {
		float: left;
	}

	.files-list-left__wrapper .tittle {
		font-size: 1rem;
		font-size: 1rem;
		color: #03081a;
		font-weight: 700;
	}

	.files-list-right__wrapper {
		float: right;
		color: #afb3bf;
		font-size: 1rem;
	}

	.nd-main-list__table {
		margin-left: -18px;
	}

	.nd-main-list__grid,
	.nd-main-list__table {
		height: calc(100% - 80px);
		width: calc(100% - 272px);
		display: inline-block;
	}

	.nd-main-list__detail,
	.nd-main-list__grid,
	.nd-main-list__table {
		min-height: 356px;
	}

	.nd-table {
		height: 100%;
	}

	.nd-detail {
		padding: 16px;
		display: inline-block;
		background: #f5f6fa;
		border-radius: 8px;
		font-size: 1rem;
		position: relative;
		overflow: auto;
	}

	.nd-main-list__detail {
		height: calc(100% - 190px);
		width: 272px;
		margin-left: 16px;
	}

	.nd-main-list__detail,
	.nd-main-list__grid,
	.nd-main-list__table {
		min-height: 356px;
	}

	.nd-detail__title {
		color: #03081a;
		font-weight: 600;
		padding-bottom: 15px;
	}

	.nd-detail__empty {
		top: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		left: 50%;
		position: absolute;
		text-align: center;
	}

	.nd-detail__empty img[data-v-0d6f9c00] {
		width: 60px;
		height: 60px;
	}

	.nd-detail__img {
		width: 240px;
		height: 240px;
		border-radius: 13px;
		position: relative;
	}

	.nd-detail__img.bg {
		background: #fff;
	}

	.nd-detail__img .category {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-height: 240px;
		max-width: 240px;
	}

	.nd-detail__props {
		color: #878c9c;
		padding: 10px;
	}

	.nd-detail__name {
		font-size: 14px;
		color: #454d5a;
		font-weight: 600;
		word-break: break-all;
		padding-top: 20px;
	}

	.nd-detail__props .prop_tittle {
		margin-top: 10px;
		font-size: 14px;
		color: #454d5a;
		font-weight: 600;
		word-break: break-all;
		text-align: left;
	}

	.nd-detail__props .prop_text {
		word-break: break-all;
		text-align: left;
	}

	.box {
		width: 100%;
		height: 100%;
	}

	.gap {
		width: 100%;
	}

	.tableBox {
		width: 100%;
		height: 100%;
		background: white;
	}

	.tableBox .grid {
		width: 100%;
		height: calc(100vh - 150px);
		overflow-y: scroll;
	}

	.el-table {
		height: 100%;
	}

	/* 格子布局样式 --- 开始*/
	.grid-item {
		float: left;
		width: 120px;
		height: 127px;
		margin: 4px 0 0 6px;
		border: 1px solid #fff;
		padding: 10px;
	}

	.grid-item.checked {
		border: 1px solid #90d8ff;
		border-radius: 5px;
		background-color: #f1f5fa;
	}

	/* 鼠标悬停*/
	.grid-item:hover {
		border: 1px solid #fff;
		border-radius: 5px;
		background-color: #f1f5fa;
	}

	/* 图片样式 */
	.grid-item>img {
		width: 64px;
		height: 64px;
		margin: 5px;
		border-radius: 12px;
		object-fit: cover;
	}

	/* 文件名称 */
	.grid-item>span {
		font-size: 0.87rem;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		width: 100%;
		text-overflow: ellipsis;
		line-height: 1.2;
		white-space: normal;
		word-break: break-all;
	}


	/* 格子布局样式 --- 结束*/

	/* 上传列表 */
	.upload-list-dialog {
		position: fixed;
		right: 24px;
		bottom: -255px;
		width: 272px;
		min-height: 300px;
		background-color: #fff;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		border: 1px solid #e2e2e2;
		box-shadow: 0 0 10px #ccc;
	}

	.dialog-header {
		display: flex;
		justify-content: space-between;
		text-align: center;
	}

	.dialog-header>div {
		/* 使每个子元素平均分配空间 */
	}

	.upload-list-dialog .dialog-header {
		padding-top: 15px;
		border-top-left-radius: 7px;
		border-top-right-radius: 7px;
		background-color: #fff;
		white-space: nowrap;
		text-overflow: ellipsis;
		color: #666;
		border-bottom: 1px solid #f7faff;
		text-indent: 1em;
		font-size: 14px;
		height: 44px;
	}

	.upload-list-dialog .item {
		margin: 0 15px;
	}

	.file-info {
		font-size: 14px;
	}

	.file-info>div {
		float: left;
	}

	.file-info .img {
		width: 5%;
		min-width: 25px;
	}

	.file-info .file-name {
		width: 35%;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.file-info .size {
		width: 15%;
	}

	.file-info .operation {
		width: 15%;
	}

	.file-progress {
		width: 100%;
	}

	.dialog-body {
		font-family: "Microsoft YaHei", SimSun;
		background-color: white;
		font-size: 12px;
		color: #424e67;
		overflow: scroll;
		height: 255px;
	}

	.custom-video-dialog>.el-dialog {
		background: black !important;
		border-radius: 10px;
	}

	.wp-share-file {
		margin-top: 8px;
	}

	.wp-share-file_dialog-footer {
		text-align: center;
		margin-top: 24px;
		font-weight: 700;
	}

	.grid-item {
		position: relative;
	}

	.play-button-overlay {
		position: absolute;
		top: 48px;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
	}

	.movie-overlay {
		position: absolute;
		top: 58px;
		right: 26px;
		pointer-events: none;
	}

	.play-button-overlay>img {
		width: 32px;
		height: 32px;
	}

	.off-file-dialog>.el-dialog {
		background: #fafbfb;
		border-radius: 10px;
	}

	.wrap .el-dialog__title {
		color: #000 !important;
	}

	.off-file-dialog .el-dialog__body {
		padding: 0 20px !important;
	}

	.off-file-dialog .off-file-textarea {
		height: 150px;
		width: 100%;
		border: 1px solid #afafaf;
		border-radius: 3px;
		padding: 5px;
		margin: 5px 0;
	}
</style>